@charset "UTF-8";
*,
::before,
::after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-style: solid;
  border-width: 0;
}

p,
table,
blockquote,
address,
pre,
iframe,
form,
figure,
dl {
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
  margin: 0;
}

body {
  font-family: "Noto Sans JP";
  font-weight: 500;
  margin: 0;
}

.sp {
  display: none;
}

@media screen and (max-width: 840px) {
  .sp {
    display: block;
  }
  .pc {
    display: none;
  }
}

div.typography {
  width: 100%;
  margin: 0 auto;
  font-family: "Noto Sans JP";
}

div.typography span.english {
  font-family: "Josefin Sans", sans-serif;
}

div.typography h1 {
  font-size: 32px;
  letter-spacing: 2px;
  position: relative;
  color: rgba(0, 0, 0, 0.8);
}

@media screen and (max-width: 840px) {
  div.typography h1 {
    font-size: 24px;
  }
}

@media screen and (max-width: 600px) {
  div.typography h1 {
    font-size: 18px;
  }
}

div.typography h1.title {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  padding: 48px;
}

@media screen and (max-width: 840px) {
  div.typography h1.title {
    padding: 24px;
  }
}

div.typography h1.title::after {
  position: absolute;
  content: " ";
  display: block;
  border-bottom: solid 1px #bbb;
  bottom: -1px;
  width: 20%;
}

div.typography h1.title span.subtitle {
  display: inline-block;
  font-size: 70%;
  margin-left: 24px;
}

div.typography h1.title2 {
  border-top: none;
}

div.typography h2 {
  font-size: 20px;
  letter-spacing: 2px;
  color: rgba(0, 0, 0, 0.6);
  position: relative;
}

@media screen and (max-width: 840px) {
  div.typography h2 {
    font-size: 20px;
  }
}

@media screen and (max-width: 600px) {
  div.typography h2 {
    font-size: 18px;
  }
}

div.typography h2.title {
  padding: 8px 8px 8px 42px;
  margin-top: 32px;
}

div.typography h2.title::after {
  position: absolute;
  content: " ";
  display: block;
  border-bottom: dotted 4px #4f4f4f;
  bottom: 24px;
  width: 24px;
  left: 8px;
}

div.typography h2.title span.subtitle {
  display: inline-block;
  font-size: 70%;
  margin-left: 24px;
}

div.typography h3 {
  font-size: 16px;
  letter-spacing: 2px;
  color: rgba(0, 0, 0, 0.6);
}

@media screen and (max-width: 840px) {
  div.typography h3 {
    font-size: 16px;
  }
}

@media screen and (max-width: 600px) {
  div.typography h3 {
    font-size: 14px;
  }
}

div.typography p {
  font-size: 16px;
  letter-spacing: 2px;
  line-height: 24px;
  font-weight: 300;
  color: rgba(0, 0, 0, 0.6);
}

@media screen and (max-width: 600px) {
  div.typography p {
    font-size: 14px;
    line-height: 22px;
  }
}

div.layout1 {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr) [1];
  grid-template-columns: repeat(1, 1fr);
  padding: 16px;
}

@media all and (-ms-high-contrast: none) {
  div.layout1::-ms-backdrop {
    display: -ms-flexbox;
    display: flex;
  }
}

div.layout2 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 48px;
}

div.layout2 > div {
  width: calc(100% / 2);
}

div.layout2 > div.w100 {
  width: 100%;
}

@media screen and (max-width: 840px) {
  div.layout2 {
    padding: 16px;
  }
}

@media screen and (max-width: 600px) {
  div.layout2 > div {
    width: calc(100% / 1);
  }
}

div.layout3 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 48px;
}

div.layout3 > div {
  width: calc(100% / 3);
}

div.layout3 > div.w100 {
  width: 100%;
}

@media screen and (max-width: 840px) {
  div.layout3 {
    padding: 16px;
  }
  div.layout3 > div {
    width: calc(100% / 2);
  }
}

@media screen and (max-width: 600px) {
  div.layout3 > div {
    width: calc(100% / 1);
  }
}

div.layout4 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 16px;
}

div.layout4 > div {
  width: calc(100% / 4);
}

div.layout4 > div.w100 {
  width: 100%;
}

@media screen and (max-width: 1200px) {
  div.layout4 > div {
    width: calc(100% / 3);
  }
}

@media screen and (max-width: 840px) {
  div.layout4 > div {
    width: calc(100% / 2);
  }
}

@media screen and (max-width: 600px) {
  div.layout4 > div {
    width: calc(100% / 1);
  }
}

div.box1 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 8px;
  -ms-flex-line-pack: start;
  align-content: flex-start;
}

div.box1 h2 {
  padding: 8px;
  width: 100%;
  font-size: 24px;
  letter-spacing: 2px;
  color: rgba(0, 0, 0, 0.6);
  position: relative;
}

@media screen and (max-width: 840px) {
  div.box1 h2 {
    font-size: 20px;
  }
}

@media screen and (max-width: 600px) {
  div.box1 h2 {
    font-size: 18px;
  }
}

div.box1 h2.title {
  padding: 8px 8px 8px 42px;
  margin-top: 32px;
}

div.box1 h2.title::after {
  position: absolute;
  content: " ";
  display: block;
  border-bottom: solid 4px #4f4f4f;
  bottom: 24px;
  width: 24px;
  left: 8px;
}

div.box1 h2.title span.subtitle {
  display: inline-block;
  font-size: 70%;
  margin-left: 24px;
}

div.box1 h3 {
  padding: 8px;
  width: 100%;
  font-weight: 700;
  position: relative;
  padding-left: 32px !important;
  color: rgba(0, 0, 0, 0.8);
}

div.box1 h3::after {
  content: "";
  position: absolute;
  left: 9px;
  top: 17px;
  width: 12px;
  height: 12px;
  background: #ddd;
}

@media screen and (max-width: 600px) {
  div.box1 h3::after {
    top: 15px;
  }
}

div.box1 p {
  padding: 8px;
  width: 100%;
  font-size: 14px;
  line-height: 28px;
  font-weight: 300;
  min-height: 64px;
  letter-spacing: 1.6px;
  color: rgba(0, 0, 0, 0.67);
}

@media screen and (max-width: 600px) {
  div.box1 p {
    min-height: auto;
  }
}

div.box1 p span.title {
  display: block;
  border-bottom: 1px solid #ddd;
  padding-bottom: 8px;
  margin-bottom: 16px;
  position: relative;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.8);
}

div.box1 p span.title::after {
  position: absolute;
  content: " ";
  display: block;
  border-bottom: solid 1px #ddd;
  bottom: -1px;
  width: 20%;
}

div.box1 p span.title span.subtitle {
  display: inline-block;
  font-size: 70%;
  margin-left: 24px;
}

div.box1 .car_lashing_point_image {
  position: relative;
  padding: 0;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

div.box1 .car_lashing_point_image span {
  position: absolute;
  top: 8px;
  left: 8px;
}

div.box1 img {
  width: 100%;
}

div.box1 div.table_box {
  padding: 8px;
  width: 100%;
}

@media screen and (max-width: 840px) {
  div.box1 div.table_box {
    overflow-x: scroll;
  }
}

div.box1 table {
  width: 100%;
  text-align: center;
  font-weight: 300;
  font-size: 14px;
  border-collapse: collapse;
  border-spacing: 0;
  padding: 8px;
  margin: 0 !important;
}

div.box1 table th {
  border: 1px solid #ddd;
  padding: 8px;
}

div.box1 table td {
  border: 1px solid #ddd;
  padding: 8px;
}

div.box1.lever_hoist_box {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

div.box1.lever_hoist_box .leverimg {
  margin-top: auto;
}

div.color_yellow div.box1 h2::after {
  border-bottom: solid 2px #ded665;
}

div.color_yellow div.box1 h3::after {
  background: #ded665;
}

div.color_yellow div.box1 p span.title::after {
  border-bottom: solid 1px #ded665;
}

footer#footer {
  background-color: #efefef;
  padding: 64px 0;
  margin-top: 0;
}

@media screen and (max-width: 840px) {
  footer#footer {
    padding: 24px 0 128px;
    margin-top: 0;
  }
}

footer#footer p.footer_title {
  text-align: center;
  margin-bottom: 36px;
  font-size: 24px;
}

@media screen and (max-width: 840px) {
  footer#footer p.footer_title {
    margin-bottom: 24px;
    font-size: 16px;
  }
}

footer#footer div.footer_shopping_guide {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr) [3];
  grid-template-columns: repeat(3, 1fr);
  width: 1200px;
  margin: 0 auto;
  row-gap: 48px;
  -webkit-column-gap: 48px;
  column-gap: 48px;
  padding-bottom: 48px;
}

@media screen and (max-width: 1296px) {
  footer#footer div.footer_shopping_guide {
    width: 100%;
    padding: 0 48px 48px;
  }
}

@media screen and (max-width: 840px) {
  footer#footer div.footer_shopping_guide {
    -ms-grid-columns: (1fr) [2];
    grid-template-columns: repeat(2, 1fr);
    padding: 0 36px 36px;
    row-gap: 36px;
    -webkit-column-gap: 36px;
    column-gap: 36px;
  }
}

@media screen and (max-width: 600px) {
  footer#footer div.footer_shopping_guide {
    -ms-grid-columns: (1fr) [1];
    grid-template-columns: repeat(1, 1fr);
    padding: 0 24px 24px;
    row-gap: 1px;
  }
}

footer#footer div.footer_shopping_guide a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  text-decoration: none;
  background-color: #fff;
  padding: 20px;
  height: 100%;
  border: 4px solid #fff;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

@media screen and (max-width: 600px) {
  footer#footer div.footer_shopping_guide a {
    padding: 12px;
  }
}

footer#footer div.footer_shopping_guide a span {
  width: 100%;
  display: block;
}

footer#footer div.footer_shopping_guide a span.title {
  color: #4f4f4f;
  padding: 0 8px 12px 24px;
  position: relative;
}

@media screen and (max-width: 600px) {
  footer#footer div.footer_shopping_guide a span.title {
    padding: 0 8px 0 24px;
    font-size: 14px;
  }
}

footer#footer div.footer_shopping_guide a span.title::after {
  position: absolute;
  content: " ";
  display: block;
  border-bottom: solid 2px #4f4f4f;
  bottom: 24px;
  width: 16px;
  left: 0;
}

@media screen and (max-width: 600px) {
  footer#footer div.footer_shopping_guide a span.title::after {
    bottom: 10px;
  }
}

footer#footer div.footer_shopping_guide a span.title span.subtitle {
  display: inline-block;
  font-size: 70%;
  margin-left: 24px;
}

footer#footer div.footer_shopping_guide a span.comment {
  color: #4f4f4f;
  font-size: 14px;
  line-height: 24px;
  font-weight: 300;
}

@media screen and (max-width: 600px) {
  footer#footer div.footer_shopping_guide a span.comment {
    display: none;
  }
}

footer#footer div.footer_shopping_guide a:hover {
  border: 4px solid #ddd;
}

footer#footer div.footer_shopping_guide a div.payicon {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr) [7];
  grid-template-columns: repeat(7, 1fr);
  -webkit-column-gap: 8px;
  column-gap: 8px;
  padding: 4px 0;
}

footer#footer div.footer_shopping_guide a div.payicon.amp_payicon {
  -ms-grid-columns: (1fr) [4];
  grid-template-columns: repeat(4, 1fr);
}

@media screen and (max-width: 600px) {
  footer#footer div.footer_shopping_guide a div.payicon {
    display: none;
  }
}

footer#footer div.footer_shopping_guide a div.payicon > span img {
  width: 100%;
}

footer#footer div.footer_menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

@media screen and (max-width: 840px) {
  footer#footer div.footer_menu {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 840px) {
  footer#footer div.footer_menu {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 0 4px;
  }
}

footer#footer div.footer_menu a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  text-decoration: none;
  padding: 0 20px;
  height: 100%;
  color: #4f4f4f;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

@media screen and (max-width: 840px) {
  footer#footer div.footer_menu a {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-size: 14px;
    margin-bottom: 24px;
  }
}

@media screen and (max-width: 600px) {
  footer#footer div.footer_menu a {
    width: 50%;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    font-size: 13px;
  }
}

footer#footer div.footer_copyright {
  padding-top: 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

footer#footer div.footer_copyright p {
  font-size: 14px;
  font-family: "Josefin Sans", sans-serif;
}

footer#footer div.footer_copyright p span {
  font-family: "Noto Sans JP";
  font-weight: 300;
  font-size: 12px;
}

@media screen and (max-width: 600px) {
  footer#footer div.footer_copyright p span {
    font-size: 10px;
  }
}

@media screen and (max-width: 600px) {
  footer#footer div.footer_copyright p {
    font-size: 10px;
  }
}

@media screen and (max-width: 840px) {
  footer#footer div.footer_copyright {
    padding-top: 6px;
  }
}

div.smp_menu {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  display: none;
  background: #ddd;
  height: 64px;
  -webkit-box-pack: space-evenly;
  -ms-flex-pack: space-evenly;
  justify-content: space-evenly;
  z-index: 4;
  -webkit-box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.4);
  box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.4);
}

@media screen and (max-width: 600px) {
  div.smp_menu {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

div.smp_menu img {
  width: 100%;
}

div.smp_menu a,
div.smp_menu button.drawerbtn {
  width: 64px;
  height: 64px;
  background: #ddd;
  position: relative;
  display: block;
  color: rgba(0, 0, 0, 0.6);
  padding: 14px 20px 20px;
}

@media screen and (max-width: 600px) {
  div.smp_menu a,
  div.smp_menu button.drawerbtn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

div.smp_menu a span,
div.smp_menu button.drawerbtn span {
  font-size: 10px;
  position: absolute;
  bottom: 8px;
  left: 0;
  display: block;
  width: 100%;
  text-align: center;
  color: rgba(0, 0, 0, 0.6);
}

div.smp_menu a span.smp_icon,
div.smp_menu button.drawerbtn span.smp_icon {
  top: 13px;
}

div.smp_menu a img,
div.smp_menu button.drawerbtn img {
  width: 24px;
  height: 24px;
}

div.search_leftmenu {
  width: 100%;
  margin-bottom: 0;
}

@media screen and (max-width: 840px) {
  div.search_leftmenu {
    overflow: hidden;
    opacity: 0;
  }
}

div.search_leftmenu .search_top {
  background: #ddd;
  padding: 24px;
}

div.search_leftmenu .search_top p {
  color: rgba(0, 0, 0, 0.8);
}

div.search_leftmenu .search_top p:nth-child(2) {
  font-size: 32px;
  font-weight: 700;
  margin-top: 12px;
}

div.search_leftmenu .search_top p:nth-child(2) span {
  font-size: 16px;
  font-weight: 500;
  display: inline-block;
  margin-left: 8px;
}

div.search_leftmenu .search_conditions {
  background: #ddd;
  padding: 0 24px 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

div.search_leftmenu .search_conditions p {
  border-top: 1px dotted #4f4f4f;
  padding: 24px 0 12px;
  width: 100%;
  font-size: 14px;
  font-weight: 700;
}

div.search_leftmenu .search_conditions a {
  background: #fff;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.6);
  padding: 8px 12px;
  width: 100%;
  margin-top: 12px;
  font-size: 14px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 3px;
}

div.search_leftmenu .search_conditions a::after {
  content: "条件の削除";
  color: rgba(0, 0, 0, 0.4);
  font-size: 11px;
}

div.search_leftmenu .search_conditions a:hover {
  background: #ddd;
}

div.search_leftmenu .search_select {
  /* padding: 0 36px; */
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

div.search_leftmenu .search_select p {
  border-bottom: 1px solid #ddd;
  font-size: 14px;
  font-weight: 700;
  padding-bottom: 8px;
  margin-top: 36px;
  position: relative;
  width: 100%;
}

div.search_leftmenu .search_select p::after {
  position: absolute;
  content: " ";
  display: block;
  border-bottom: solid 1px #ccc;
  bottom: -1px;
  width: 20%;
}

div.search_leftmenu .search_select a {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.6);
  padding: 0px 12px;
  height: 28px;
  width: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 12px;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  font-size: 14px;
  border-radius: 3px;
}

div.search_leftmenu .search_select a.small {
  font-size: 11px;
}

div.search_leftmenu .search_select a.w100 {
  width: 100%;
}

div.search_leftmenu .search_select a::after {
  content: "";
  width: 8px;
  height: 8px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  border-top: 1px solid #bbb;
  border-right: 1px solid #bbb;
}

div.search_leftmenu .search_select a:hover {
  background: #ddd;
}

div.search_leftmenu .search_select.color_select {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr) [4];
  grid-template-columns: repeat(4, 1fr);
  padding-top: 16px;
  row-gap: 8px;
  -webkit-column-gap: 8px;
  column-gap: 8px;
}

div.search_leftmenu .search_select.color_select a {
  height: 40px;
  background-color: #ddd;
  padding: 0;
  margin: 0;
  width: 100%;
  border: 1px solid #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

div.search_leftmenu .search_select.color_select a::after {
  display: none;
}

div.search_leftmenu .search_select.color_select a.belt_color_415 {
  background: #ea433d;
}

div.search_leftmenu .search_select.color_select a.belt_color_416 {
  background: #0294d3;
}

div.search_leftmenu .search_select.color_select a.belt_color_417 {
  background: #404145;
}

div.search_leftmenu .search_select.color_select a.belt_color_418 {
  background: #fff;
  border: 1px solid #ddd;
}

div.search_leftmenu .search_select.color_select a.belt_color_419 {
  background: #e4d44c;
}

div.search_leftmenu .search_select.color_select a.belt_color_420 {
  background: #797470;
}

div.search_leftmenu .search_select.color_select a.belt_color_421 {
  background: #958d4e;
}

div.search_leftmenu .search_select.color_select a.belt_color_422 {
  background: #ff8252;
}

div.search_leftmenu .search_select.color_select a.belt_color_660 {
  background-image: url("https://cdn.shopify.com/s/files/1/0610/1994/7144/files/icon2021-black-gray.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}

div.search_leftmenu .search_select.color_select a.belt_color_661 {
  background-image: url("https://cdn.shopify.com/s/files/1/0610/1994/7144/files/icon2021-black-white.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}

div.search_leftmenu .search_select.color_select a.belt_color_662 {
  background-image: url("https://cdn.shopify.com/s/files/1/0610/1994/7144/files/icon2021-od-black.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}

div.search_leftmenu .search_select.color_select a.belt_color_663 {
  background-image: url("https://cdn.shopify.com/s/files/1/0610/1994/7144/files/icon2021-white-orange.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}

div.search_leftmenu .search_select.color_select a.belt_color_844 {
  background-image: url("https://cdn.shopify.com/s/files/1/0610/1994/7144/files/icon2021-black-yellow.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}

div.search_leftmenu .search_select.color_select a.belt_color_845 {
  background-image: url("https://cdn.shopify.com/s/files/1/0610/1994/7144/files/icon2021-orange-black.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}

div.search_leftmenu .search_select.color_select a span {
  font-size: 10px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  opacity: 0;
}

div.search_leftmenu .search_select.color_select a:hover {
  border: 1px solid #000;
}

div.search_leftmenu .search_select.color_select a:hover span {
  opacity: 1;
}

div.bonding_blet_search {
  position: relative;
}

div.bonding_blet_search button.title {
  width: 100%;
  position: relative;
  margin: 0px 0 8px;
  padding: 8px;
  background: #ff7043;
  color: #fff;
  font-weight: 700;
  font-family: "Noto Sans JP";
}

div.bonding_blet_search button.title::after {
  content: "";
  position: absolute;
  width: 8px;
  height: 8px;
  top: 16px;
  right: 16px;
  border-right: 1px solid #fff;
  border-bottom: 1px solid #fff;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

div.bonding_blet_search button.closebtn {
  width: 240px;
  padding: 8px;
  margin: 5px auto;
  background: #fff;
  font-size: 12px;
  font-family: "Noto Sans JP";
  cursor: pointer;
}

div.bonding_blet_search div.searchform {
  text-align: center;
  top: 0;
  left: 0;
  background-color: #fff;
  z-index: 1;
  -webkit-transition: 0.8s;
  transition: 0.8s;
  height: 0;
  overflow: hidden;
  position: relative;
}

div.bonding_blet_search div.searchform::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 20px;
  background: #fff;
  -webkit-box-shadow: 10px 10px 10px 10px rgba(0, 0, 0, 0.1);
  box-shadow: 10px 10px 10px 10px rgba(0, 0, 0, 0.1);
}

div.bonding_blet_search div.searchform.open {
  height: 540px;
}

div.bonding_blet_search div.searchform p {
  padding-left: 16px;
  font-size: 12px;
  text-align: left;
}

div.bonding_blet_search div.searchform p.title {
  background-color: #ddd;
  text-align: center;
  font-size: 14px;
  margin-bottom: 8px;
  font-weight: 700;
  padding: 8px;
}

div.bonding_blet_search div.searchform div.searchform_item {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 8px 16px;
  position: relative;
}

@media screen and (max-width: 601px) {
  div.bonding_blet_search div.searchform div.searchform_item {
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
  }
}

div.bonding_blet_search div.searchform div.searchform_item label {
  position: relative;
  width: auto !important;
  margin: 0;
}

div.bonding_blet_search div.searchform div.searchform_item label.delete_color,
div.bonding_blet_search div.searchform div.searchform_item label.delete_hook,
div.bonding_blet_search
  div.searchform
  div.searchform_item
  label.delete_belt_width,
div.bonding_blet_search
  div.searchform
  div.searchform_item
  label.delete_heat_resisting,
div.bonding_blet_search
  div.searchform
  div.searchform_item
  label.delete_filter-p-m-belt-_type,
div.bonding_blet_search
  div.searchform
  div.searchform_item
  label.delete_filter-p-m-belt-_color,
div.bonding_blet_search
  div.searchform
  div.searchform_item
  label.delete_filter-p-m-belt-_width,
div.bonding_blet_search
  div.searchform
  div.searchform_item
  label.delete_filter-p-m-products-_spec {
  display: none;
}

div.bonding_blet_search div.searchform div.searchform_item label input {
  display: none;
}

div.bonding_blet_search
  div.searchform
  div.searchform_item
  label
  input:checked
  + span {
  background-color: #ddd;
}

div.bonding_blet_search div.searchform div.searchform_item label span {
  -webkit-transition: 0.4s;
  transition: 0.4s;
  cursor: pointer;
  white-space: nowrap;
  display: inline-block;
  padding: 2px 12px 3px;
  border: 1px solid #ddd;
  border-right: none;
  font-size: 14px;
  transition: 0.4s;
  line-height: 1.85;
}

div.bonding_blet_search div.searchform div.searchform_item label span:hover {
  background-color: #ddd;
}

div.bonding_blet_search
  div.searchform
  div.searchform_item
  label:last-child
  span {
  border: 1px solid #ddd;
  margin-right: 16px;
}

div.bonding_blet_search div.searchform div.searchbtn2018btn {
  margin-top: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

div.bonding_blet_search div.searchform div.searchbtn2018btn button {
  background-color: rgba(0, 0, 0, 0.6);
  color: #fff;
  padding: 6px 24px 8px;
  border-radius: 3px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border: 1px solid rgba(0, 0, 0, 0.6);
  cursor: pointer;
  font-family: "Noto Sans JP";
}

div.bonding_blet_search div.searchform div.searchbtn2018btn button:hover {
  background: #fff;
  color: rgba(0, 0, 0, 0.6);
}

div.bonding_blet_search div.searchform div.search_item_type {
  width: 100%;
  height: 1px;
  overflow: hidden;
}

div.search_title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid #ddd;
  width: 100%;
  overflow: hidden;
  padding: 12px;
}

div.search_title div.search_item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

@media screen and (max-width: 840px) {
  div.search_title div.search_item {
    overflow-x: scroll;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
}

@media screen and (max-width: 840px) {
  div.search_title {
    padding: 8px;
  }
}

div.search_title p {
  font-size: 16px;
  font-weight: 700;
  margin: 12px;
}

@media screen and (max-width: 840px) {
  div.search_title p {
    width: 100%;
    padding: 0;
    font-size: 14px;
    margin: 8px;
  }
}

div.search_title a {
  background: #fff;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.6);
  padding: 4px 12px 1px;
  font-size: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border: 1px solid #ddd;
  margin: 12px;
  white-space: nowrap;
  border-radius: 3px;
}

@media screen and (max-width: 840px) {
  div.search_title a {
    font-size: 11px;
    margin: 8px;
  }
}

div.search_title a::after {
  content: "条件を削除 ×";
  color: rgba(0, 0, 0, 0.4);
  font-size: 11px;
  margin-left: 16px;
}

div.search_title a:hover {
  background: #ddd;
}

div.search_select2 {
  border-top: 2px dotted #ddd;
  margin-top: 32px;
}

div.main {
  padding-left: 48px;
  width: 100%;
  overflow: hidden;
}

@media screen and (max-width: 840px) {
  div.main {
    padding-left: 0;
  }
}

div#contents div.container-fluid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

div#contents div.container-fluid div#side_left {
  grid-area: le;
  float: none;
  width: 288px;
  padding-right: 0;
  border-right: 1px solid #ddd;
}

@media screen and (max-width: 840px) {
  div#contents div.container-fluid div#side_left {
    display: none;
  }
}

div#contents div.container-fluid div#main {
  width: calc(100% - 288px);
  font-family: "Josefin Sans", "Noto Sans JP", sans-serif;
}

@media screen and (max-width: 840px) {
  div#contents div.container-fluid div#main {
    width: 100%;
  }
}

div#contents div.container-fluid div#contents_bottom {
  width: 100%;
}

div.item_list2021 {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr) [4];
  grid-template-columns: repeat(4, 1fr);
  background: #ddd;
  row-gap: 1px;
  -webkit-column-gap: 1px;
  column-gap: 1px;
  padding: 1px 0;
}

@media screen and (max-width: 1201px) {
  div.item_list2021 {
    -ms-grid-columns: (1fr) [3];
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 840px) {
  div.item_list2021 {
    padding: 0;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
  }
}

@media screen and (max-width: 600px) {
  div.item_list2021 {
    -ms-grid-columns: (1fr) [2];
    grid-template-columns: repeat(2, 1fr);
  }
}

div.item_list2021 div.item-listbox {
  padding: 0;
}

div.item_list2021 div.item-listbox a {
  background: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  height: 100%;
  border: solid 12px #fff;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  padding: 12px;
}

div.item_list2021 div.item-listbox a:hover {
  border: solid 12px #ddd;
}

div.item_list2021 div.item-listbox a div.item_photo {
  width: 100%;
}

div.item_list2021 dt.item_name {
  margin: 8px 0 0 0;
}

div#result_info_box {
  margin: 0;
  padding: 24px;
}

@media screen and (max-width: 840px) {
  div#result_info_box {
    padding: 16px;
    border-left: none;
  }
}

div#result_info_box form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

div#result_info_box form div#result_info_box__menu_box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

div#result_info_box form div#result_info_box__menu_box > div {
  margin-left: 24px;
}

div#result_info_box form p#result_info_box__item_count strong {
  display: none;
}

@media screen and (max-width: 840px) {
  div#result_info_box form p#result_info_box__item_count strong {
    display: inline-block;
  }
}

div#result_info_box form select {
  border: none;
  text-align: center;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #ddd;
}

div.drawer_menu {
  position: fixed;
  height: 120vh;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 3;
  -webkit-transform: translateY(120vh);
  transform: translateY(120vh);
  -webkit-transition: 0.8s;
  transition: 0.8s;
  background: rgba(0, 0, 0, 0.7);
}

div.drawer_menu button.closebtn {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 56px;
  margin: 0;
  background: #ddd;
  padding: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

div.drawer_menu button.closebtn span {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 12px;
  right: 16px;
}

div.drawer_menu button.closebtn > div {
  width: 24px;
}

div.drawer_menu .drawer_menu_list {
  position: absolute;
  top: 56px;
  right: 0;
  width: 100%;
  height: 120vh;
  overflow-y: scroll;
}

div.drawer_menu .drawer_menu_list .drawer_menu_detail {
  padding-bottom: 50vh;
  background: white;
}

div.drawer_menu .drawer_menu_list .drawer_menu_detail p {
  color: rgba(0, 0, 0, 0.6);
}

div#wrapper.open div.drawer_menu {
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

div.drawer_menu_detail p {
  font-weight: 700;
  text-align: center;
  padding: 16px;
}

div.drawer_menu_detail p.leftentry {
  padding: 12px;
}

div.drawer_menu_detail button {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 56px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 24px;
  position: relative;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  background: #efefef;
  border-bottom: 1px solid #ddd;
  border-top: 1px solid #fff;
}

div.drawer_menu_detail button::after {
  content: "";
  position: absolute;
  top: 15px;
  right: 17px;
  width: 16px;
  height: 16px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  border-bottom: 1px solid #ff7043;
  border-right: 1px solid #ff7043;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

div.drawer_menu_detail button.open::after {
  -webkit-transform: rotate(225deg);
  transform: rotate(225deg);
  top: 25px;
}

div.drawer_menu_detail > a {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 56px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 24px;
  border-bottom: 1px solid #ddd;
  border-top: 1px solid #fff;
  background: #efefef;
  position: relative;
  color: rgba(0, 0, 0, 0.6);
}

div.drawer_menu_detail > a::after {
  content: "";
  position: absolute;
  top: 19px;
  right: 17px;
  width: 12px;
  height: 12px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  border-top: 1px solid #ff7043;
  border-right: 1px solid #ff7043;
}

div.acordion_menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
  overflow: hidden;
  height: 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

div.acordion_menu a {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 48px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 32px;
  border-bottom: 1px dotted #ddd;
  position: relative;
  color: rgba(0, 0, 0, 0.6);
}

div.acordion_menu a::after {
  content: "";
  position: absolute;
  top: 17px;
  right: 17px;
  width: 12px;
  height: 12px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  border-top: 1px solid #ff7043;
  border-right: 1px solid #ff7043;
}

div#pankz {
  width: 100%;
  border-bottom: 1px solid #ddd;
  border-left: 1px solid #ddd;
}

@media screen and (max-width: 840px) {
  div#pankz {
    border-left: none;
  }
}

div.pankz2021 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding: 8px;
  border-bottom: 1px solid #ddd;
}

@media screen and (max-width: 840px) {
  div.pankz2021 {
    border-left: none;
    overflow-x: scroll;
  }
}

@media screen and (max-width: 600px) {
  div.pankz2021 {
    overflow-x: scroll;
  }
}

div.pankz2021 p {
  white-space: nowrap;
  margin: 0;
  padding: 0;
  font-size: 14px;
}

@media screen and (max-width: 600px) {
  div.pankz2021 p {
    font-size: 12px;
  }
}

div.pankz2021 p:last-child {
  padding: 8px;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

@media screen and (max-width: 840px) {
  div.pankz2021 p:last-child {
    border-left: none;
    overflow-x: unset;
  }
}

div.pankz2021 p a {
  text-decoration: none;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 8px;
  color: rgba(0, 0, 0, 0.4);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  position: relative;
}

div.pankz2021 p a:hover {
  color: rgba(0, 0, 0, 0.8);
}

div.pankz2021 p a::after {
  content: "";
  display: block;
  margin-left: 8px;
  width: 8px;
  height: 8px;
  border-top: 1px solid #ddd;
  border-right: 1px solid #ddd;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

@media screen and (max-width: 840px) {
  div#side_left {
    display: none;
  }
}

div#detail_wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  font-family: "Josefin Sans", "Noto Sans JP", sans-serif;
  margin-bottom: 0px;
  padding: 0;
  border-bottom: 1px solid #ddd;
}

div#detail_wrap div#item_photo_area {
  width: calc(100% - 480px);
  border-right: 1px solid #ddd;
  background: #fff;
  padding: 48px;
}

@media screen and (max-width: 1468px) {
  div#detail_wrap div#item_photo_area {
    width: calc(100% - 360px);
  }
}

@media screen and (max-width: 840px) {
  div#detail_wrap div#item_photo_area {
    width: 80%;
    border-right: none;
    border-left: none;
    padding: 0;
    margin: 0 auto;
  }
}

@media screen and (max-width: 640px) {
  div#detail_wrap div#item_photo_area {
    width: 100%;
    margin: 0;
  }
}

div#detail_wrap div#item_photo_area .slick-list img {
  padding: 0;
}

div#detail_wrap div#item_photo_area ul.slick-dots {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

div#detail_wrap div#item_photo_area button {
  outline: none;
}

div#detail_wrap section#item_detail_area {
  width: 480px;
  padding: 48px;
}

@media screen and (max-width: 1468px) {
  div#detail_wrap section#item_detail_area {
    width: 360px;
  }
}

@media screen and (max-width: 840px) {
  div#detail_wrap section#item_detail_area {
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
  div#detail_wrap section#item_detail_area {
    padding: 24px;
  }
}

div#detail_wrap section#item_detail_area h3 {
  font-family: "Josefin Sans", "Noto Sans JP", sans-serif;
  letter-spacing: 1.3px;
}

div#product_tag_box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 1px;
}

div#product_tag_box span.product_tag_list {
  border: none;
  border-radius: 1px;
  padding: 6px 8px 3px;
  line-height: 12px;
  font-size: 12px;
  margin: 1px;
}

header#header {
  padding-bottom: 0;
  border-bottom: 1px solid #ddd;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

@media screen and (max-width: 600px) {
  header#header {
    height: 56px;
    -webkit-box-shadow: 0 -12px 25px 0 rgba(0, 0, 0, 0.4);
    box-shadow: 0 -12px 25px 0 rgba(0, 0, 0, 0.4);
  }
}

div#header2021 div.menu a.cartbtn span.quanty {
  top: 0;
  background: #ff7043;
  width: 23px;
  height: 23px;
  color: #fff;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 10px;
  right: 0;
  left: auto;
}

@media screen and (max-width: 600px) {
  div#header2021 div.menu a.cartbtn span.quanty {
    top: 8px;
    width: 20px;
    height: 20px;
  }
}

div#header2021 div.menu::after {
  display: none;
}

p#btn_menu {
  display: none;
}

@media screen and (max-width: 600px) {
  h1.description {
    font-size: 10px !important;
    padding: 0 24px;
  }
}

div.linebtn a {
  background: #21ba1a;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #fff;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 2px 0;
  font-size: 12px;
  height: 56px;
}

div.linebtn a img {
  width: 48px;
  margin-right: 4px;
}

div.header_description h1 {
  text-align: center;
  height: 56px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid #ddd;
  font-size: 16px;
}

@media screen and (max-width: 840px) {
  div.header_description h1 {
    font-size: 10px;
    padding: 0 24px;
    border-top: 1px solid #ddd;
  }
}

div.header_description p.band {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 56px;
}

@media screen and (max-width: 840px) {
  div.header_description p.band {
    display: block;
    height: auto;
  }
}

div.header_description p.band img {
  display: block;
}

@media screen and (max-width: 840px) {
  div.header_description p.band img {
    width: 100%;
  }
}

div.header_description p.band.amazon {
  background-color: #313b45;
}

div.smp_search2021 {
  padding: 0 14px;
  border-bottom: 1px solid #ddd;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 56px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

div.smp_search2021 form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media screen and (max-width: 500px) {
  div.smp_search2021 form {
    width: 100%;
  }
}

div.smp_search2021 form p {
  font-size: 14px;
  margin: 0;
  padding: 0;
}

div.smp_search2021 form p:nth-child(1) {
  width: calc(100% - 32px);
}

div.smp_search2021 form p:nth-child(2) {
  width: 32px;
  padding: 4px;
}

div.smp_search2021 form p button {
  background: #fff;
  margin: 0;
  padding: 0;
}

p.category_imgbox_title {
  border-top: 1px solid #ddd;
  width: 100%;
  padding: 24px;
  font-size: 24px;
  color: rgba(0, 0, 0, 0.8);
}

@media screen and (max-width: 840px) {
  p.category_imgbox_title {
    font-size: 14px;
    padding: 16px;
  }
}

p.category_imgbox_title span {
  display: inline-block;
  font-size: 60%;
  margin-left: 16px;
  font-family: "Josefin Sans", sans-serif;
}

@media screen and (max-width: 840px) {
  p.category_imgbox_title span {
    font-size: 80%;
  }
}

div.category_imgbox_scroll {
  width: 100%;
}

@media screen and (max-width: 840px) {
  div.category_imgbox_scroll {
    overflow-x: scroll;
  }
}

div.category_imgbox {
  display: -ms-grid;
  display: grid;
  padding: 1px;
  background: #ddd;
  gap: 1px 1px;
  display: grid;
  -ms-grid-columns: (1fr) [8];
  grid-template-columns: repeat(8, 1fr);
  -ms-grid-rows: (1fr) [3];
  grid-template-rows: repeat(3, 1fr);
}

@media screen and (max-width: 1201px) {
  div.category_imgbox {
    -ms-grid-columns: (1fr) [6];
    grid-template-columns: repeat(6, 1fr);
    -ms-grid-rows: (1fr) [4];
    grid-template-rows: repeat(4, 1fr);
  }
}

@media screen and (max-width: 840px) {
  div.category_imgbox {
    -ms-grid-columns: (1fr) [8];
    grid-template-columns: repeat(8, 1fr);
    -ms-grid-rows: (1fr) [3];
    grid-template-rows: repeat(3, 1fr);
  }
}

div.category_imgbox p:nth-child(1) {
  -ms-grid-row: 1;
  -ms-grid-row-span: 2;
  -ms-grid-column: 1;
  -ms-grid-column-span: 2;
  grid-area: 1 / 1 / 3 / 3;
}

div.category_imgbox p:nth-child(2) {
  -ms-grid-row: 2;
  -ms-grid-row-span: 2;
  -ms-grid-column: 3;
  -ms-grid-column-span: 2;
  grid-area: 2 / 3 / 4 / 5;
}

div.category_imgbox p:nth-child(3) {
  -ms-grid-row: 2;
  -ms-grid-row-span: 2;
  -ms-grid-column: 7;
  -ms-grid-column-span: 2;
  grid-area: 2 / 7 / 4 / 9;
}

div.category_imgbox p:nth-child(4) {
  -ms-grid-row: 1;
  -ms-grid-row-span: 2;
  -ms-grid-column: 5;
  -ms-grid-column-span: 2;
  grid-area: 1 / 5 / 3 / 7;
}

div.category_imgbox p:nth-child(5) {
  -ms-grid-row: 3;
  -ms-grid-row-span: 1;
  -ms-grid-column: 1;
  -ms-grid-column-span: 1;
  grid-area: 3 / 1 / 4 / 2;
}

div.category_imgbox p:nth-child(6) {
  -ms-grid-row: 1;
  -ms-grid-row-span: 1;
  -ms-grid-column: 3;
  -ms-grid-column-span: 1;
  grid-area: 1 / 3 / 2 / 4;
}

div.category_imgbox p:nth-child(7) {
  -ms-grid-row: 3;
  -ms-grid-row-span: 1;
  -ms-grid-column: 6;
  -ms-grid-column-span: 1;
  grid-area: 3 / 6 / 4 / 7;
}

div.category_imgbox p:nth-child(8) {
  -ms-grid-row: 3;
  -ms-grid-row-span: 1;
  -ms-grid-column: 5;
  -ms-grid-column-span: 1;
  grid-area: 3 / 5 / 4 / 6;
}

div.category_imgbox p:nth-child(9) {
  -ms-grid-row: 3;
  -ms-grid-row-span: 1;
  -ms-grid-column: 2;
  -ms-grid-column-span: 1;
  grid-area: 3 / 2 / 4 / 3;
}

div.category_imgbox p:nth-child(10) {
  -ms-grid-row: 1;
  -ms-grid-row-span: 1;
  -ms-grid-column: 4;
  -ms-grid-column-span: 1;
  grid-area: 1 / 4 / 2 / 5;
}

div.category_imgbox p:nth-child(11) {
  -ms-grid-row: 1;
  -ms-grid-row-span: 1;
  -ms-grid-column: 7;
  -ms-grid-column-span: 1;
  grid-area: 1 / 7 / 2 / 8;
}

div.category_imgbox p:nth-child(12) {
  -ms-grid-row: 1;
  -ms-grid-row-span: 1;
  -ms-grid-column: 8;
  -ms-grid-column-span: 1;
  grid-area: 1 / 8 / 2 / 9;
}

@media screen and (max-width: 1201px) {
  div.category_imgbox p:nth-child(1) {
    -ms-grid-row: 1;
    -ms-grid-row-span: 2;
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
    grid-area: 1 / 1 / 3 / 3;
  }
  div.category_imgbox p:nth-child(2) {
    -ms-grid-row: 3;
    -ms-grid-row-span: 2;
    -ms-grid-column: 5;
    -ms-grid-column-span: 2;
    grid-area: 3 / 5 / 5 / 7;
  }
  div.category_imgbox p:nth-child(3) {
    -ms-grid-row: 1;
    -ms-grid-row-span: 2;
    -ms-grid-column: 5;
    -ms-grid-column-span: 2;
    grid-area: 1 / 5 / 3 / 7;
  }
  div.category_imgbox p:nth-child(4) {
    -ms-grid-row: 3;
    -ms-grid-row-span: 2;
    -ms-grid-column: 2;
    -ms-grid-column-span: 2;
    grid-area: 3 / 2 / 5 / 4;
  }
  div.category_imgbox p:nth-child(5) {
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
    -ms-grid-column: 4;
    -ms-grid-column-span: 1;
    grid-area: 1 / 4 / 2 / 5;
  }
  div.category_imgbox p:nth-child(6) {
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
    -ms-grid-column: 3;
    -ms-grid-column-span: 1;
    grid-area: 1 / 3 / 2 / 4;
  }
  div.category_imgbox p:nth-child(7) {
    -ms-grid-row: 2;
    -ms-grid-row-span: 1;
    -ms-grid-column: 3;
    -ms-grid-column-span: 1;
    grid-area: 2 / 3 / 3 / 4;
  }
  div.category_imgbox p:nth-child(8) {
    -ms-grid-row: 3;
    -ms-grid-row-span: 1;
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
    grid-area: 3 / 1 / 4 / 2;
  }
  div.category_imgbox p:nth-child(9) {
    -ms-grid-row: 4;
    -ms-grid-row-span: 1;
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
    grid-area: 4 / 1 / 5 / 2;
  }
  div.category_imgbox p:nth-child(10) {
    -ms-grid-row: 3;
    -ms-grid-row-span: 1;
    -ms-grid-column: 4;
    -ms-grid-column-span: 1;
    grid-area: 3 / 4 / 4 / 5;
  }
  div.category_imgbox p:nth-child(11) {
    -ms-grid-row: 4;
    -ms-grid-row-span: 1;
    -ms-grid-column: 4;
    -ms-grid-column-span: 1;
    grid-area: 4 / 4 / 5 / 5;
  }
  div.category_imgbox p:nth-child(12) {
    -ms-grid-row: 2;
    -ms-grid-row-span: 1;
    -ms-grid-column: 4;
    -ms-grid-column-span: 1;
    grid-area: 2 / 4 / 3 / 5;
  }
}

@media screen and (max-width: 840px) {
  div.category_imgbox p:nth-child(1) {
    -ms-grid-row: 1;
    -ms-grid-row-span: 2;
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
    grid-area: 1 / 1 / 3 / 3;
  }
  div.category_imgbox p:nth-child(2) {
    -ms-grid-row: 2;
    -ms-grid-row-span: 2;
    -ms-grid-column: 7;
    -ms-grid-column-span: 2;
    grid-area: 2 / 7 / 4 / 9;
  }
  div.category_imgbox p:nth-child(3) {
    -ms-grid-row: 1;
    -ms-grid-row-span: 2;
    -ms-grid-column: 5;
    -ms-grid-column-span: 2;
    grid-area: 1 / 5 / 3 / 7;
  }
  div.category_imgbox p:nth-child(4) {
    -ms-grid-row: 2;
    -ms-grid-row-span: 2;
    -ms-grid-column: 3;
    -ms-grid-column-span: 2;
    grid-area: 2 / 3 / 4 / 5;
  }
  div.category_imgbox p:nth-child(5) {
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
    -ms-grid-column: 3;
    -ms-grid-column-span: 1;
    grid-area: 1 / 3 / 2 / 4;
  }
  div.category_imgbox p:nth-child(6) {
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
    -ms-grid-column: 4;
    -ms-grid-column-span: 1;
    grid-area: 1 / 4 / 2 / 5;
  }
  div.category_imgbox p:nth-child(7) {
    -ms-grid-row: 3;
    -ms-grid-row-span: 1;
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
    grid-area: 3 / 1 / 4 / 2;
  }
  div.category_imgbox p:nth-child(8) {
    -ms-grid-row: 3;
    -ms-grid-row-span: 1;
    -ms-grid-column: 5;
    -ms-grid-column-span: 1;
    grid-area: 3 / 5 / 4 / 6;
  }
  div.category_imgbox p:nth-child(9) {
    -ms-grid-row: 3;
    -ms-grid-row-span: 1;
    -ms-grid-column: 6;
    -ms-grid-column-span: 1;
    grid-area: 3 / 6 / 4 / 7;
  }
  div.category_imgbox p:nth-child(10) {
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
    -ms-grid-column: 7;
    -ms-grid-column-span: 1;
    grid-area: 1 / 7 / 2 / 8;
  }
  div.category_imgbox p:nth-child(11) {
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
    -ms-grid-column: 8;
    -ms-grid-column-span: 1;
    grid-area: 1 / 8 / 2 / 9;
  }
  div.category_imgbox p:nth-child(12) {
    -ms-grid-row: 3;
    -ms-grid-row-span: 1;
    -ms-grid-column: 2;
    -ms-grid-column-span: 1;
    grid-area: 3 / 2 / 4 / 3;
  }
}

div.category_imgbox img {
  width: 100%;
  vertical-align: bottom;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

div.category_imgbox a {
  position: relative;
  display: block;
  color: rgba(0, 0, 0, 0.7);
  overflow: hidden;
  font-size: 18px;
  letter-spacing: 2px;
  padding-bottom: 100%;
}

@media screen and (max-width: 1200px) {
  div.category_imgbox a {
    font-size: 16px;
  }
}

@media screen and (max-width: 900px) {
  div.category_imgbox a {
    font-size: 12px;
  }
}

@media screen and (max-width: 840px) {
  div.category_imgbox a {
    font-size: 12px;
  }
}

@media screen and (max-width: 600px) {
  div.category_imgbox a {
    font-size: 10px;
  }
}

div.category_imgbox a img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

div.category_imgbox a span {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: rgba(0, 0, 0, 0);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  padding: 12px;
}

@media screen and (max-width: 840px) {
  div.category_imgbox a span {
    padding: 12px;
  }
}

div.category_imgbox a:hover img {
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}

div.contents_top2021 {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr) [2];
  grid-template-columns: repeat(2, 1fr);
  background: #ddd;
  row-gap: 1px;
  -webkit-column-gap: 1px;
  column-gap: 1px;
}

div.contents_top2021.cr_gap20210324 {
  row-gap: 8px;
  -webkit-column-gap: 8px;
  column-gap: 8px;
}

div.contents_top2021 p {
  background-color: #fff;
}

div.contents_top2021 a {
  display: -ms-grid;
  display: grid;
  background-color: #fff;
  row-gap: 12px;
  grid-template:
    "img img img"
    "... co1 ..."
    "... co2 ..."/
    24px 1fr 24px;
  padding-bottom: 24px;
  text-decoration: none;
}

@media screen and (max-width: 648px) {
  div.contents_top2021 a {
    row-gap: 6px;
    grid-template:
      "img img img"
      "... co1 ..."
      "... co2 ..."/
      12px 1fr 12px;
    padding-bottom: 12px;
  }
}

div.contents_top2021 a img {
  width: 100%;
  grid-area: img;
}

div.contents_top2021 a span {
  display: block;
}

div.contents_top2021 a span:nth-of-type(1) {
  grid-area: co1;
  padding-top: 12px;
  color: #ff7043;
}

@media screen and (max-width: 648px) {
  div.contents_top2021 a span:nth-of-type(1) {
    padding-top: 6px;
    font-size: 14px;
  }
}

div.contents_top2021 a span:nth-of-type(2) {
  grid-area: co2;
  font-size: 14px;
  font-weight: 300;
  color: rgba(0, 0, 0, 0.6);
}

@media screen and (max-width: 648px) {
  div.contents_top2021 a span:nth-of-type(2) {
    font-size: 12px;
    line-height: 24px;
  }
}

div.toppage2021 {
  width: 100%;
  display: block;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

div.toppage2021 img {
  width: 100%;
  display: block;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  /*　ローカル用　*/
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  /*　ローカル用　*/
}

div.toppage2021 div.toplist {
  width: 100%;
  margin: 0 auto;
  display: -ms-grid;
  display: grid;
  grid-gap: 1px 1px;
  background-color: #efefef;
  -ms-grid-columns: (1fr) [6];
  grid-template-columns: repeat(6, 1fr);
  padding: 0;
}

div.toppage2021 div.toplist.toplist2 {
  -ms-grid-columns: (1fr) [5];
  grid-template-columns: repeat(5, 1fr);
}

div.toppage2021 div.toplist.toplist3 {
  -ms-grid-columns: (1fr) [4];
  grid-template-columns: repeat(4, 1fr);
}

@media screen and (max-width: 1200px) {
  div.toppage2021 div.toplist {
    -ms-grid-columns: (1fr) [3];
    grid-template-columns: repeat(3, 1fr);
  }
  div.toppage2021 div.toplist.toplist2 {
    -ms-grid-columns: (1fr) [4];
    grid-template-columns: repeat(4, 1fr);
  }
  div.toppage2021 div.toplist.toplist3 {
    -ms-grid-columns: (1fr) [3];
    grid-template-columns: repeat(3, 1fr);
  }
}

div.toppage2021 div.toplist p {
  margin: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

div.toppage2021 div.toplist p a {
  width: 100%;
  text-align: center;
  text-decoration: none;
  color: gray;
  margin: 0 auto;
  display: block;
  background-color: white;
  padding: 24px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
  height: 100%;
}

div.toppage2021 div.toplist p a:hover img {
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}

@media screen and (max-width: 1200px) {
  div.toppage2021 div.toplist p a {
    padding: 8px;
    font-size: 70%;
  }
}

p.leftentry {
  padding: 32px 32px 0;
}

@media screen and (max-width: 640px) {
  p.leftentry {
    padding: 12px;
    border-bottom: 1px solid #ddd;
  }
}

p.leftentry a {
  background: #efefef;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 64px;
  border: 2px solid #ff7043;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 1.5px;
  color: #4f4f4f;
}

@media screen and (max-width: 640px) {
  p.leftentry a {
    height: 48px;
  }
}

p.leftentry a:hover {
  background: #fff;
  color: #ff7043;
}

p.leftentry.line a {
  background: #00b900;
  color: #fff;
  border: 2px solid #00b900;
}

p.leftentry.line a:hover {
  background: #fff;
  color: #00b900;
}

p.linecomment {
  padding: 0 32px;
  font-size: 12px;
}

/* div.leftmenu_down {
  padding: 16px;
} */

div.leftmenu_down p {
  margin: 0;
  padding: 16px 0;
}

div.leftmenu_down p a {
  display: block;
}

div.leftmenu_down p a img {
  width: 100%;
}

div.leftmenu_down .manual_dl {
  padding: 16px;
}

@media screen and (max-width: 840px) {
  div.leftmenu_down .manual_dl {
    display: -ms-grid;
    display: grid;
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
    grid-column: 1/3;
    padding-right: 0;
  }
}

div.leftmenu_down .manual_dl a {
  background-color: #ff7043;
  color: #fff;
  padding: 16px 16px;
  display: block;
  border: 2px solid #ff7043;
  -webkit-transition: ease-in-out 0.4s;
  transition: ease-in-out 0.4s;
  font-weight: 700;
  text-align: center;
}

@media screen and (max-width: 840px) {
  div.leftmenu_down .manual_dl a {
    display: block !important;
    background-color: #ff7043 !important;
    text-align: center;
    padding: 16px 16px 14px !important;
    line-height: 1;
    font-size: 4vw;
  }
}

div.leftmenu_down .manual_dl a:hover {
  background-color: transparent;
  color: #ff7043;
}

@media screen and (max-width: 640px) {
  iframe#launcher {
    bottom: 64px !important;
    margin: 16px !important;
  }
}

div.detail_cart_box_fb_line {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-top: 8px;
}

div.detail_cart_box_fb_line div {
  width: calc(50% - 12px);
}

div.detail_cart_box_fb_line div button.btn {
  padding: 0;
  background: #fff;
  border: 1px solid #ff7043;
  font-size: 12px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  color: #ff7043;
}

div.detail_cart_box_fb_line div button.btn:hover {
  background: rgba(255, 112, 67, 0.4);
  color: #fff;
}

div#detail_cart_box__button_area button#add-cart {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 2px;
  background: #ff7043;
  border: 4px solid rgba(255, 112, 67, 0.6);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

div#detail_cart_box__button_area button#add-cart:hover {
  color: #ff7043;
  background: #fff;
}

div#detail_cart_box__button_area button#add-cart.preorder_btn::after {
  content: "[予約]";
  display: inline-block;
  margin-left: 8px;
}

a.tenkenbtn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 32px;
  background: #fff;
  padding-top: 3px;
  font-size: 14px;
  border: 1px solid #ff7043;
  color: #ff7043;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

a.tenkenbtn:hover {
  background: rgba(255, 112, 67, 0.4);
  color: #fff;
}

div#complete_flow_box {
  width: 100%;
}

div#deliveradd_input {
  width: 920px;
}

@media screen and (max-width: 640px) {
  div#deliveradd_input {
    width: 100%;
  }
}

div#deliveradd_input h2 {
  margin-bottom: 24px;
}

div#deliveradd_input_box__top_button div.btn_group p {
  width: 320px;
  margin: 0 auto 16px;
}

@media screen and (max-width: 360px) {
  div#deliveradd_input_box__top_button div.btn_group p {
    width: 100%;
  }
}

div#detail_box__body {
  width: 720px;
  margin-top: 36px;
}

@media screen and (max-width: 840px) {
  div#detail_box__body {
    width: 100%;
    margin-top: 16px;
  }
}

dt.reviewstar {
  position: relative;
  font-size: 12px;
  color: #ff7043;
  display: inline-block;
  margin-right: 4px;
  letter-spacing: 2px;
}

dt.reviewstar::before {
  content: "☆☆☆☆☆";
}

dt.reviewstar.star1::before {
  content: "★☆☆☆☆";
}

dt.reviewstar.star2::before {
  content: "★★☆☆☆";
}

dt.reviewstar.star3::before {
  content: "★★★☆☆";
}

dt.reviewstar.star4::before {
  content: "★★★★☆";
}

dt.reviewstar.star5::before {
  content: "★★★★★";
}

p.review_attention {
  font-size: 16px;
  padding: 0 32px 32px;
}

@media screen and (max-width: 840px) {
  p.review_attention {
    font-size: 14px;
    padding: 0 24px 24px;
  }
}

.car_lashing_item_detail .mark1,
.car_lashing_item_detail .mark2 {
  display: inline-block;
  padding: 2px 8px;
  margin-right: 12px;
}

@media screen and (max-width: 840px) {
  .car_lashing_item_detail .mark1,
  .car_lashing_item_detail .mark2 {
    display: table-cell;
  }
}

.car_lashing_item_detail .mark1 {
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
  border-radius: 10px;
}

.car_lashing_item_detail .mark2 {
  border: 1px solid rgba(0, 0, 0, 0.6);
}

.car_lashing_item_detail .datail_img .title2 {
  background: #fff;
  color: rgba(0, 0, 0, 0.8);
  text-align: center;
  padding: 11px;
  font-weight: 700;
  border: 1px solid rgba(0, 0, 0, 0.8);
  font-size: 14px;
  min-height: auto;
}

.car_lashing_item_detail .datail_img .img_hook {
  position: relative;
  margin-top: 16px;
}

.car_lashing_item_detail .datail_img .img_hook span {
  font-size: 12px;
  position: absolute;
  top: 0;
  left: 0;
  min-height: auto;
}

.car_lashing_item_detail .datail_img2_area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.car_lashing_item_detail .datail_img2_area .datail_img2 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 50%;
  margin-top: 12px;
  border: 1px solid rgba(0, 0, 0, 0.6);
}

@media screen and (max-width: 840px) {
  .car_lashing_item_detail .datail_img2_area .datail_img2 {
    width: 100%;
  }
}

.car_lashing_item_detail .datail_img2_area .datail_img2:first-child {
  border-right: none;
}

@media screen and (max-width: 840px) {
  .car_lashing_item_detail .datail_img2_area .datail_img2:first-child {
    border: 1px solid rgba(0, 0, 0, 0.6);
  }
}

.car_lashing_item_detail .datail_img2_area .datail_img2 p:first-child {
  width: 100%;
}

.car_lashing_item_detail .datail_img2_area .datail_img2 p:nth-child(2) {
  width: 100%;
  font-size: 12px;
  padding: 12px;
}

.car_lashing_strength_box_area {
  width: 100%;
  margin-top: 8px;
}

.car_lashing_strength_box_area .car_lashing_strength_box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.6);
  padding: 16px;
}

.car_lashing_strength_box_area .car_lashing_strength_box p {
  width: 40%;
  padding: 0;
  text-align: center;
  position: relative;
  font-size: 12px;
  line-height: 1.75;
}

@media screen and (max-width: 840px) {
  .car_lashing_strength_box_area .car_lashing_strength_box p {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    font-size: 2vw;
  }
}

@media screen and (max-width: 480px) {
  .car_lashing_strength_box_area .car_lashing_strength_box p {
    font-size: 3vw;
  }
}

.car_lashing_strength_box_area .car_lashing_strength_box p span {
  font-size: 50px;
  display: block;
  margin-top: 8px;
  line-height: 1;
}

@media screen and (max-width: 840px) {
  .car_lashing_strength_box_area .car_lashing_strength_box p span {
    font-size: 8.5vw;
  }
}

@media screen and (max-width: 480px) {
  .car_lashing_strength_box_area .car_lashing_strength_box p span {
    font-size: 12.5vw;
  }
}

.car_lashing_strength_box_area .car_lashing_strength_box p:first-child span {
  text-decoration: line-through;
}

.car_lashing_strength_box_area .car_lashing_strength_box p:first-child::after {
  content: none;
  position: absolute;
  top: 50%;
  right: 14%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-color: transparent transparent transparent #161b1f;
  border-style: solid;
  border-width: 25px 0 25px 43.3px;
}

@media screen and (max-width: 840px) {
  .car_lashing_strength_box_area
    .car_lashing_strength_box
    p:first-child::after {
    right: 8%;
    border-width: 4vw 0 4vw 6vw;
  }
}

.car_lashing_strength_box_area .car_lashing_strength_box p:last-child {
  font-weight: 700;
  color: #c00000;
}

.car_lashing_strength_box_area
  .car_lashing_strength_box
  .car_lashing_strength_arrow_area {
  width: 20%;
}

.car_lashing_strength_box_area
  .car_lashing_strength_box
  .car_lashing_strength_arrow_area
  .car_lashing_strength_arrow {
  margin: 0;
}

.car_lashing_strength_box_area
  .car_lashing_strength_box
  .car_lashing_strength_arrow_area
  .car_lashing_strength_arrow::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 0;
  height: 0;
  border-color: transparent transparent transparent #161b1f;
  border-style: solid;
  border-width: 25px 0 25px 43.3px;
}

.viewmoretitle {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}

.viewmoretitle h1 {
  border: none;
}

.viewmoretitle_area {
  padding-right: 48px;
}

@media screen and (max-width: 840px) {
  .viewmoretitle_area {
    display: -ms-grid;
    display: grid;
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
    grid-column: 1/3;
    padding-right: 0;
  }
}

.viewmoretitle_area a {
  background-color: #ff7043;
  color: #fff;
  padding: 16px 48px;
  display: block;
  border: 2px solid #ff7043;
  -webkit-transition: ease-in-out 0.4s;
  transition: ease-in-out 0.4s;
  font-weight: 700;
}

@media screen and (max-width: 840px) {
  .viewmoretitle_area a {
    display: block !important;
    background-color: #ff7043 !important;
    text-align: center;
    padding: 16px 16px 14px !important;
    line-height: 1;
    font-size: 4vw;
  }
}

.viewmoretitle_area a:hover {
  background-color: transparent;
  color: #ff7043;
}
/*# sourceMappingURL=typography.css.map */
